button {
  cursor: pointer;
}
body {
  font-family: "Lora", serif;
  font-weight: 400;
  background: url("./bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
input,
button {
  font-family: "Lora", serif;
}
.NavBar {
  position: fixed;
  background: url("./frame.png") repeat;
  background-position: bottom;
  top: 0px;
  width: 100%;
  transition: transform 350ms ease-in-out;
  z-index: 5;
  text-align: center;
}
.rightPanel {
  margin-left: auto;
}
#NavLarge {
  display: flex;
  justify-content: left;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: flex-start;
}
.floatStat {
  float:right;
 
}
.FULLSCREEN {
  position: fixed;
  z-index:100000;
  width: 100%;
  height: 100%;
  left:0; top: 0;
  background: #1a1a1a;
  padding: 16px;
 

}
.FULLSCREEN > .KCInput {
  width: 100%;
}
#NavLarge > * {
  align-self: center;
}
h1,
h2, h3 {
  font-family: "Aleo", sans-serif;
}
.percentages {
  position: absolute;
  left:0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  z-index: 10;
  user-select: none;
  color: white;
  text-shadow: 0px 1px 6px rgba(0,0,0,0.63);
}
h1 {
  letter-spacing: 2px;
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
.rs {
  line-height: 1em;
  margin: 0px;
}
p,
li {
  line-height: 1.75rem;
  text-align: justify;
}

.centeredText {
  text-align: center;
}

img[src=""] {
  display: none;
}
.postFeature {
  width: 100%;
  height: 330px;
  object-fit: cover;
}

.postBlog .postTitle {
  font-size: 20px;
}
.blogFeatureImage,
.blogBigFeatureImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 4px;
}
.kcb {
  display: block;
  text-align: center;
}
canvas {
  position: fixed !important;
}

.blogBigFeatureImage {
  height: 50vh;
  border-radius: 0px;
}
#blogPosts,
.triGrid {
  position: relative;
  display: grid;
  grid-gap: 4vmin;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4vmin 0;
  background: #251c12;
  border: solid 1px;
  border-image: url("./borderstyle.png") /* source */ 12 / /* slice */ 12px 12px
    12px 12px / /* width */ 12px 12px 12px 12px /* outset */ round;
  padding: 2vmin;
  grid-gap: 4vmin;
  margin-top: 16px;
  
}

.YIRGrid {
  border: none;
  background: transparent;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}
.YIRStat {
  width: 22%;
  flex: 1 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.statCount {
  font-size: 40px;
}
.postAuthorArea {
  padding: 8px;
  border-left: solid 2px #3f3e3d;
}
.postArea {
  width: 100%;

  padding-bottom: 100px;
}
#status {
  width: 100%;
  display: flex;
  position: sticky;
}
#status div {
  line-height: 50px;
}
.Status {
  flex: 1 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}
.scs {
  max-width: 1024px !important;
}
.postArea {
  font-size: 1.2em;

  line-height: 1.6em;
  display: grid;
  grid-template-columns: [full-start] minmax(4vmin, auto) [wide-start] minmax(
      auto,
      240px
    ) [main-start] min(900px, calc(100% - 8vw)) [main-end] minmax(auto, 240px) [wide-end] minmax(
      4vmin,
      auto
    ) [full-end];
}
.rule a {
  color: unset;
  text-decoration: none;
}

.rule h2 {
  text-transform: uppercase;
  display: inline;
}
.postArea img {
  max-width: 100%;
  height: auto;
}
.skip-link {
  position: absolute;
  top: -60px;
  left: 0;
  background: rgba(25, 33, 40, 0.5);

  width: 100%;
  color: white;
  padding: 16px;
  text-align: center;
  transition: top 200ms ease-in-out;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}
.helpApp a {
  text-decoration: none;
  color: unset;
}
.postArea > * {
  grid-column: main-start/main-end;
}
.postArea p {
  font-family: Georgia, "Times New Roman", Times, serif;
}
.isOpaque {
  background: rgb(25, 33, 40);
}
.kg-image-card img {
  margin: auto;
}
.kg-width-full img {
  width: 100%;
  height: auto;
}
.kg-width-full {
  grid-column: full-start/full-end;
}
.noEnd {
  padding-bottom: 0px;
}
figure {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

.backButton {
  padding: 16px;
  background: none;
  color: white;
  font-size: 20px;

  border: none;
  margin: 16px;
}

.helpTypes,
.button,
.KCInput,
.voteLinks a,
.postBlog {
  border: 1px solid;
  padding: 4px 6px;
  background: #a09172;
  font-size: 18px;
  color: #3f3e3d;

  text-decoration: none;
  text-align: center;
  border-image: url("./buttonborder.png") /* source */ 3 / /* slice */ 3px 3px
    3px 3px / /* width */ 3px 3px 3px 3px /* outset */ round; /* repeat */
}
.button {
  cursor: pointer;
}
.KCInput {
  text-align: left;
}
.KCBlock {
  display: block;
}
.KCSpaced {
  margin-top: 16px;
}

.voteLinks a::after,
.button::after,
.helpTypes::after {
  content: "";
  display: inline-block;
  margin-left: 3px;
  border-right: 1px solid #3f3e3d;
  border-bottom: 1px solid #3f3e3d;
  width: 10px;
  height: 10px;
  transform: rotate(315deg);
}
.voteLinks a {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  max-width: 400px;
}
.statValue {
  display: flex;
  height: 30px;
  margin-top: 8px;
  width: 100%;
 
}
.padBig {
  margin-top:60px;
}
.navMobile span {display: block;
  padding: 8px;}
.statGraphName {
  line-height: 30px;
  width: 200px;
  text-align: right;
  padding-right: 16px;
}
.statPercentageView {
  position: relative;
  flex: 1 0 auto;
  height: 30px;
 
}
.statNumber {
  padding-left: 16px;
}
.statProgress,
.statNumber {
  width: 100%;
  height: 30px;
  position: absolute;
  line-height: 30px;
 
}
.statProgress {
  width: 0%;
  border: #4e3bbf solid 2px;

  transition: width 150ms ease-in-out;
  background: linear-gradient(30deg, #510080 0%, #531994 16%, #522ba9 33%, #4e3bbf 50%, #454bd4 66%, #355ae9 83%, #006aff 100%);
  border-radius: 6px;
  background-attachment: fixed;
}
.fullPageError {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: grid;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  align-items: center;
  justify-items: center; /* adjusted */
}
.pageCentered {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.fullPageError > div {
  padding: 16px;
  width: 100%;
}
.err {
  display: block;
  background: #433d43;
  padding: 4px 6px;
  color: white;
  height: 200px;
  resize: none;
  border-radius: 6px;
  width: 100%;
  margin: 16px 0px;
}
@media (max-width: 1000px) {
  #blogPosts,
  .triGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 700px) {
  #blogPosts,
  .triGrid {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  .YIRStat {
    width: 35%;
  }
 
}

.postContent {
  padding: 8px 0px;
  letter-spacing: 0.6px;
  line-height: 1.6em;
}
.postTitle {
  text-decoration: underline;
  color: white;
}
.banCount {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 30px;

  margin-bottom: 8px;
}

.progress {
  position: absolute;
  z-index: 0;
  height: 30px;
  transition: transform 350ms ease-in;
  transform: scaleX(0);
  transform-origin: left;

  background: darkgoldenrod;
  background: linear-gradient(to bottom, #d5b302 0%, #a52027 100%);
  background-attachment: fixed;
  display: inline-block;
  width: 100%;
}
.banInner {
  position: absolute;

  width: 100%;
  justify-content: space-around;
}
.banInner div {
  display: inline-block;
  padding: 0px 8px;
  line-height: 30px;
  color: white;
  vertical-align: top;
  height: 30px;
}
.banner {
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
  width: calc(100% - 75px);
}
.bannerCount {
  width: 75px;
  text-align: right;
}
#BanTable {
  border-collapse: collapse;
  color: #251c12;
  border: 1px solid;
  margin: 8px;
  background: #a09172;
  border-image: url("./buttonborder.png") /* source */ 4 / /* slice */ 4px 4px
    4px 4px / /* width */ 4px 4px 4px 4px /* outset */ round; /* repeat */

  width: calc(100% - 16px);
  white-space: nowrap;
  display: block;
  overflow-x: scroll;
}
#BanTable th,
#BanTable td {
  text-align: left;
  min-width: 190px;
  padding: 8px 4px;
}
#BannerTable {
  width: auto;
}

#BannerTable th,
#BannerTable td {
  text-align: left;
  min-width: 30%;
  padding: 8px;
}
.wavyContainer {
  min-height: 70vh;
}
.wavySmallContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.padTop {
  padding-top: 52px;
}
.wavySmallContainer > .blobView {
  bottom: unset;
  top: calc(-50% + 110px);
}
.wavySmallContainer > .backdropMode {
  height: 50%;
}
.hasBlur > .Centerer {
  padding-top: 120px;
}

.mediumText > .smallTag {
  position: relative;
  bottom: 9px;
}
.smallTag {
  font-size: 14px;
  font-family: Inter, sans-serif;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background: #1c150d;
  margin-left: 8px;
}
.smallrow {
  min-width: 25px !important;
}

.KCForms {
  padding: 8px;
  margin-top: 32px;
  background-color: transparent;
}
.KCFormsHeader {
  font-weight: bold;
  letter-spacing: 1px;
  padding: 8px 6px;
}
.KCSearchBox {
  border: 1px solid;
  padding: 4px 6px;

  font-size: 18px;
  color: #251c12;
  display: block;
  width: calc(100% - 16px);
  margin: 8px;
  text-decoration: none;
  background: #a09172;
  border-image: url("./buttonborder.png") /* source */ 4 / /* slice */ 4px 4px
    4px 4px / /* width */ 4px 4px 4px 4px /* outset */ round; /* repeat */
}
.KCTextHeight {
  min-height: 200px;
}
.KCNonFull {
  min-width: 200px;
  width: 200px;
}
.KCInlined {
  display: inline-block;
}
.subServer {
  display: inline-block;
  color: white;
}
.subServer div {
  display: inline-block;
}
.serverCount {
  margin: 0px 6px;
}
.fullSize {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.iframeEmbed {
  width: 100%;
  height: 100%;
}
table,
thead,
tbody,
tr {
  width: 100%;
}
thead tr th {
  padding: 8px;
  color: #491712;
}
tbody tr th {
  padding: 8px;
}

.postDate {
  opacity: 0.7;
}
.postHint {
  opacity: 0.5;
}

.postExcerpt {
  text-align: justify;
  margin-bottom: 1em;
  display: -webkit-box;
  overflow-y: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.aPost {
  text-decoration: none;
}
.spacer {
  margin-top: 16px;
}
.staffList {
  font-size: 18px;
}
.NavBar a {
  margin-left: 4px;
  margin-right: 4px;
  padding: 16px 8px;
  display: inline-block;
  color: white;
  text-decoration: none;
}
.forumFrame {
  border: none;
  width: 100%;
  height: 120vh;
}
.hamburger {
  padding: 6px;
}
.NavBar a:hover {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18,
    0 0 30px #49ff18, 0 0 40px #49ff18, 0 0 55px #49ff18, 0 0 75px #49ff18;
}
.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
}
.Loader {
  padding-top: 64px;
  height: 160px;
  margin-bottom: 20px;
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.separator {
  width: 1px;
  height: 40px;
  margin: 0 4px;
  background: #28363f;
}
.logoDiv {
  width: 40px;
  height: 40px;
  transition: filter 150ms ease-in-out;
  filter: saturate(0.2);
  background-image: url("./logo@1x.png");
  background-size: 100% 100%;
  display: inline-block;
}
.logoDiv:hover {
  filter: saturate(1);
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  top: 75px;
  font-size: 20px;
  letter-spacing: 12px;
  position: absolute;

  color: #fff;
  left: 15%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.staffList {
  text-align: center;
}
.statusGrids {
  border-radius: 4px;
}

.HeroLogo {
  width: 192px;
  height: 192px;
  animation: pulse 4s infinite;
  animation-timing-function: ease-in-out;
}

.splitter {
  width: calc(50% - 25px);
  display: inline-block;
  margin-right: 25px;
}
a {
  color: white;
}

.highlight {
  color: #ff2f55;
  display: block;
  font-weight: bold;
}
.homePageHero {
  width: 100%;
  text-align: center;
}

.leftColumn {
  font-size: 10vh;
}
.splitScreen .left > .button {
  width: calc(100% - 8px);
  margin-top: 14px;
  display: block;
}

.wave {
  position: relative;
  top: 5px;
}
.positioner {
  position: relative;
  width: 100%;
  height: 0;
}
.relative {
  position: relative;
}
.bigger {
  font-size: 80px;
  letter-spacing: 3px;
}
.floatWave {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 2;
}
.blobView {
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: 0;
  height: 100%;
}

.nmh {
  min-height: initial;
}
.minHeight {
  min-height: 100vh;
}
.minHeightLite {
  min-height: 40vh;
  padding-bottom: 32px;
}
.statPrimary {
  font-size: 30px;
  text-align: center;
  margin: 62px 0px;
}
.statSecondary {
  font-size: 20px;
}
@keyframes showSlow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sappear {
  opacity: 1;
  animation: showSlow 350ms ease-in-out;
}
.rootYIR {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 1);
}
.mixOverlay {
  mix-blend-mode: overlay;
}
.bodyfsm {
  margin-top: 0px;
}
#kcvideo {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.backdropMode {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: -2;
  user-select: none;
}
.pad {
  padding-left: 30px;
  padding-top: 96px;
}
.intro {
  width: 600px;
  max-width: 100%;
}

.bigText {
  font-size: 60px;
  font-family: "Aleo", serif;
}

.mediumText {
  font-family: "Aleo", serif;
  font-size: 40px;
}

.footer {
  background-color: #101519;
  padding-bottom: 1em;
  padding-top: 1em;
  text-align: center;
  position: relative;
  text-shadow: 1px 1px 2px rgba(30, 30, 30, 0.78);
}
.evenLighter {
  opacity: 0.7;
}
.footer a {
  color: white;
}

#NavSmol {
  display: none;
  align-items: center;
  padding: 8px 16px;
}

.warns {
  color: rgb(255, 255, 255);

  background-color: rgba(255, 187, 0, 0.425);
  padding: 4px 2px;
  border-radius: 4px;
}
.Dropdown-menu {
  z-index: 10;
}
.navMobile {
  top: 0px;
  background: #181818;
  position: fixed;
  z-index: 4;
  transition: transform 350ms ease-in-out;
  padding: 16px;
  transform: translateY(-100%);
  padding-top: 60px;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}
.smolDiv {
  width: 20px;
  height: 20px;
}
.postArea > .header {
  line-height: 1.2em;
}
.mainSwitch > div {
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mainSwitch::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  min-height: 100%;
}
.navMobile a {
  display: block;
  padding: 8px;
}
.navMobileOpened {
  transform: translateY(0%);
}
#NavSmol .header {
  font-size: 24px;
  margin-left: 8px;
  font-weight: 300;
  vertical-align: middle;
}
#NavSmol > * {
  vertical-align: middle;
}
.smallBorder > input,
.smallBorder > button {
  width: 100%;
}
.border,
.smallBorder,
.KCForms,
#BlogApp > .padTop {
  border: solid 1px;
  background: #251c12;
  margin-top: 18px;
  border-image: url("./borderstyle.png") /* source */ 12 / /* slice */ 12px 12px
    12px 12px / /* width */ 12px 12px 12px 12px /* outset */ round; /* repeat */
}

.smallBorder {
  width: 400px;
  margin: 0 auto;
  margin-top: 18px;
}

.splitScreen {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 100%;
  gap: 0px 0px;
  grid-template-areas: ". .";
}

.splitScreen .left {
  border-right: solid 2px hsl(32, 35%, 7%);
  margin-right: 8px;
}

/*    Mobile ONLY   */
@media only screen and (max-width: 800px) {
  .bigText {
    font-size: 40px;
  }
  .mediumText {
    font-size: 20px;
  }
  .Centerer {
    border-radius: 0px;

    backdrop-filter: none;
  }
  .statPrimary {
    font-size: 20px;
    text-align: center;
    margin: 32px 0px;
  }
  .statSecondary {
    font-size: 16px;
  }
  h1 {
    -webkit-text-stroke: initial;
    letter-spacing: 2px;
    color: inherit;
    letter-spacing: normal;
    font-size: 28px;
  }
  h2 {
    font-size: 20px;
  }

  #NavLarge {
    display: none;
  }

  #NavSmol {
    display: flex;
  }
  .navGroup div {
    display: inline-block;
  }
  .header {
    font-size: 28px;
    color: white;
    -webkit-text-stroke: initial;
    letter-spacing: normal;
  }
  .floatWave {
    margin-top: 1em;
    position: relative;
    top: -20px;
  }
  .hasBlur > .Centerer {
    padding-top: 150px;
  }
  .Centerer > .header {
    font-size: 40px;
  }
}
#NavUnderLarge {
  position: fixed;
  top: 52px;
  left: 0px;
  width: 100%;
  background: hsl(32, 35%, 8%);
  z-index: 999;
}
#NavUnderLarge > div {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 64px;

}
.NavBarStickMode {
  color: white;
  background: none;
  backdrop-filter: none;
}

.hideNavBar {
  transform: translateY(-100%);
}
.imageWithText img {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.imageWithText > div {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.imageWithText {
  position: absolute;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.rel {
  position: relative;

  width: 100%;
  height: 60vh;
}
